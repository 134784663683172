.statisticResource {
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  padding-top: 10px; }

.statisticResourceTitles {
  border-bottom: 2px solid black;
  font-weight: 600;
  padding-bottom: 10px;
  padding-top: 10px; }

.statisticResourceTotal {
  border-top: 2px solid black;
  font-weight: 600;
  padding-bottom: 10px;
  padding-top: 10px; }
