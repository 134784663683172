/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.webeatCoverVideoFileTypeSelect.webeatCoverVideoFileTypeSelect {
  color: #df3f41;
  flex: 0 0 58px;
  width: 58px;
  flex-shrink: 0; }
  .webeatCoverVideoFileTypeSelect.webeatCoverVideoFileTypeSelect span {
    margin-left: 10px; }
