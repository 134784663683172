/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.webeatCoverVideoUploadingModal__container {
  width: 100%;
  padding: 0 20px; }

.webeatCoverVideoUploadingModal__row {
  min-height: 180px; }

.webeatCoverVideoUploadingModal__title {
  font-size: 1.4rem;
  margin-bottom: 20px; }

.webeatCoverVideoUploadingModal .ant-progress-bg {
  background-color: #df3f41; }

.webeatCoverVideoUploadingModal .ant-progress-text {
  display: none; }

.webeatCoverVideoUploadingModal .ant-progress-outer {
  margin-right: 0;
  padding-right: 0; }
