/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.webeatCoverVideoUpload {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 0 10px; }

.webeatCoverVideoMediaButton {
  width: 100%;
  min-width: 16px;
  text-align: left;
  border: none;
  margin: 10px 0;
  padding: 0;
  color: #df3f41;
  box-shadow: none;
  background-color: transparent; }
  .webeatCoverVideoMediaButton span {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: underline; }
