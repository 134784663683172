/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.login {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 5%;
  justify-content: center;
  align-items: center;
  background-color: white; }
  .login__leftContent {
    padding-left: 0; }
    .login__leftContent .text {
      font-size: 35px;
      display: block;
      padding-top: 100px;
      font-family: "Nunito", sans-serif;
      color: #2b2830; }
    .login__leftContent .enviroment {
      font-size: 20px;
      font-family: "Nunito", sans-serif; }
    .login__leftContent .logo {
      background-image: url("../img/ISDIN_LOGO.png");
      background-size: 180px;
      background-repeat: no-repeat;
      height: 80px;
      margin-top: 23px;
      opacity: 0.9; }
  .login__rightContent {
    background-color: #d8d8d8;
    border-radius: 6px;
    width: 370px; }
    .login__rightContent .progressBar {
      position: 'absolute';
      top: -10px; }
    .login__rightContent .ant-form-item {
      margin-top: 5px; }
    .login__rightContent form {
      padding: 50px; }
      .login__rightContent form label {
        padding-top: 50px;
        font-size: 35px;
        color: #2b2830;
        font-weight: bold; }
      .login__rightContent form input {
        border-bottom-color: black;
        border-top-color: white;
        border-left-color: white;
        border-right-color: white;
        color: black;
        margin-bottom: 0px !important; }
      .login__rightContent form i {
        color: #df3f41;
        opacity: 0.5;
        margin-bottom: 0px; }
    .login__rightContent .buttonLogin {
      height: 50px;
      width: 60%;
      margin-top: 10px;
      background-color: #df3f41;
      border-color: #df3f41;
      margin-left: 115px;
      margin-top: 30px; }
    .login__rightContent .buttonForgotPassword {
      margin-left: 74px;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
  .login .enviromentMovile {
    display: none; }

@media only screen and (max-width: 890px) {
  .login {
    height: 100%;
    width: 100%; }
    .login__leftContent {
      display: none; }
    .login .enviromentMovile {
      font-size: 20px;
      font-family: "Nunito", sans-serif;
      color: white;
      display: block;
      padding-bottom: 5px; }
    .login .logoMovile {
      background-image: url("../img/ISDIN_LOGO.png");
      background-size: 180px;
      background-repeat: no-repeat;
      height: 90px;
      display: block;
      margin-left: 100px;
      margin-bottom: 40px; } }

@media only screen and (max-width: 400px) {
  .login {
    height: 100%;
    width: 100%; }
    .login__leftContent {
      display: none; }
    .login .enviromentMovile {
      font-size: 20px;
      font-family: "Nunito", sans-serif;
      color: white;
      display: block;
      padding-bottom: 5px; }
    .login .logoMovile {
      margin-left: 50px;
      margin-bottom: 60px; }
    .login__rightContent {
      padding: 2px;
      width: 275px; }
      .login__rightContent .progressBar {
        top: -10px; }
      .login__rightContent form {
        padding: 35px; }
      .login__rightContent button {
        height: 50px;
        width: 60%;
        margin-top: 10px;
        background-color: #df3f41;
        margin-left: 40px; } }
