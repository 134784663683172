/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.fileTypeButton,
.noFrameButton,
.mediaButton {
  text-align: left;
  border: none;
  color: #df3f41;
  box-shadow: none;
  background-color: transparent; }
  .fileTypeButton span,
  .noFrameButton span,
  .mediaButton span {
    text-decoration: underline; }

.fileTypeSelect {
  color: #df3f41; }
  .fileTypeSelect span {
    margin-left: 10px; }

.mediaButton {
  margin: 10px 0;
  padding: 0;
  width: 100%; }
  .mediaButton span {
    width: 100%;
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

.uploadButton,
.removeButton {
  box-shadow: none; }
  .uploadButton__preview img,
  .removeButton__preview img {
    max-width: 50vw;
    max-height: 50vh;
    object-fit: cover; }
  .uploadButton__margin-top,
  .removeButton__margin-top {
    margin-top: 0px; }

.previewModal {
  margin: auto !important;
  width: 60vw !important; }
  .previewModal .uploadButton__preview {
    text-align: center; }
    .previewModal .uploadButton__preview--youtube-video {
      position: relative;
      overflow: hidden;
      margin-top: 20px;
      width: 100%;
      padding-top: 56.25%; }
    .previewModal .uploadButton__preview--youtube-video iframe {
      position: absolute;
      top: 0px;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%; }
    .previewModal .uploadButton__preview img {
      max-width: 100%; }

.previewModalPage {
  margin: auto !important;
  width: 90vw !important;
  height: 100vh !important; }
  .previewModalPage .ant-modal-content {
    height: 95% !important; }
  .previewModalPage .ant-modal-body {
    height: 100% !important; }

.uploadingModal__container {
  width: 100%;
  padding: 0 20px; }

.uploadingModal__row {
  min-height: 180px; }

.uploadingModal__title {
  font-size: 1.4rem;
  margin-bottom: 20px; }

.uploadingModal .ant-progress-bg {
  background-color: #df3f41; }

.uploadingModal .ant-progress-text {
  display: none; }

.uploadingModal .ant-progress-outer {
  margin-right: 0;
  padding-right: 0; }
