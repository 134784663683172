.massive-delete.header {
  margin-bottom: 20px; }

.massive-delete.title {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px; }

.massive-delete.icon {
  margin-right: 20px; }

.massive-delete.show-more {
  margin-top: 20px; }

.massive-delete.show-more button {
  border: none;
  box-shadow: none; }

.massive-delete.container-buttons {
  text-align: right;
  margin-bottom: 1rem; }

.massive-delete.container-button-center {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  justify-content: center; }

.massive-delete.container-buttons button {
  margin: 5px;
  justify-content: center; }

.massive-delete.advice {
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 5px;
  color: red; }
