.webeat-home-sections__panel--active .ant-collapse-header {
  box-shadow: 0 2px 0 rgba(17, 214, 17, 0.25); }

.webeat-home-sections__panel--active .ant-collapse-header i svg {
  fill: #11d611; }

.webeat-home-sections__panel--inactive .ant-collapse-header {
  box-shadow: 0 2px 0 rgba(199, 29, 29, 0.25); }

.webeat-home-sections__panel--inactive .ant-collapse-header i svg {
  fill: #c71d1d; }

.webeat-home-sections__panel--content {
  padding: 0px;
  margin: 0px;
  border: none; }

.webeat-home-sections__field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px; }

.webeat-home-sections__label {
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px !important; }
