.webeatCoverVideoPreviewModal__preview--youtube-video {
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  width: 100%;
  padding-top: 56.25%; }
  .webeatCoverVideoPreviewModal__preview--youtube-video iframe {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }
