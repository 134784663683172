.failed-emails-container {
  max-height: 400px;
  overflow: auto; }

.failed-email {
  display: flex;
  flex-direction: column; }
  .failed-email:not(:last-child) {
    padding-bottom: 12px; }
  .failed-email__email {
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 4px; }
