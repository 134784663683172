.webeat-preheader-role__panel--active {
  box-shadow: 0 2px 0 rgba(17, 214, 17, 0.25); }
  .webeat-preheader-role__panel--active .ant-collapse-header i svg {
    fill: #11d611; }

.webeat-preheader-role__panel--inactive {
  box-shadow: 0 2px 0 rgba(199, 29, 29, 0.25); }
  .webeat-preheader-role__panel--inactive .ant-collapse-header i svg {
    fill: #c71d1d; }

.webeat-preheader-role__panel--registered {
  padding-left: 30px; }

.webeat-preheader-role__panel--registered-custom {
  padding-left: 60px; }

.webeat-preheader-role__panel--content {
  padding: 0px;
  margin: 0px;
  border: none; }

.webeat-preheader-role__message--active {
  box-shadow: 0 2px 0 rgba(17, 214, 17, 0.25); }
  .webeat-preheader-role__message--active .ant-collapse-header i svg {
    fill: #11d611; }

.webeat-preheader-role__message--inactive {
  box-shadow: 0 2px 0 rgba(199, 29, 29, 0.25); }
  .webeat-preheader-role__message--inactive .ant-collapse-header i svg {
    fill: #c71d1d; }
