.webeat-home-section-hero-spacer {
  margin-top: 32px; }

.webeat-home-section-hero-block {
  max-width: 400px; }

.webeat-home-sections__panel--active .ant-collapse-header {
  box-shadow: 0 2px 0 rgba(17, 214, 17, 0.25); }

.webeat-home-sections__panel--active .ant-collapse-header i svg {
  fill: #11d611; }

.webeat-home-sections__panel--inactive .ant-collapse-header {
  box-shadow: 0 2px 0 rgba(199, 29, 29, 0.25); }

.webeat-home-sections__panel--inactive .ant-collapse-header i svg {
  fill: #c71d1d; }
