/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.header__menuRight {
  float: right;
  margin-right: 5px;
  margin-top: 15px; }
  .header__menuRight .configUser {
    height: 100%;
    color: #d8d8d8;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer; }
    .header__menuRight .configUser__avatar {
      background-color: #646464; }
      .header__menuRight .configUser__avatar .anticon {
        font-size: 20px; }
    .header__menuRight .configUser__user {
      color: #2b2830;
      padding-left: 8px;
      padding-right: 8px; }
      .header__menuRight .configUser__user :hover {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); }
    .header__menuRight .configUser .anticon.anticon-down {
      color: #2b2830; }

.header__platformSelector {
  margin-right: 30px;
  width: 180px; }
  @media (max-width: 768px) {
    .header__platformSelector {
      width: 50px; } }

.ant-layout-header {
  z-index: 9;
  background: transparent; }
