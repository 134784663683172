.challenge-users.header {
  margin-bottom: 20px; }

.challenge-users.title {
  font-size: 16px;
  margin-bottom: 5px; }

.challenge-users.show-more {
  margin-top: 20px; }
  .challenge-users.show-more button {
    border: none;
    box-shadow: none; }

.challenge-users.container-buttons {
  text-align: right;
  margin-bottom: 1rem; }
  .challenge-users.container-buttons button {
    margin: 1px; }
