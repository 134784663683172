.custom-modal-update-liww-user {
  min-width: 600px !important; }

.ant-modal-confirm-title {
  font-weight: 800 !important; }

.edit-result-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
  max-height: 70vh;
  overflow-y: auto; }
  .edit-result-wrapper .title {
    font-size: 16px;
    font-weight: 600; }
  .edit-result-wrapper .line {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    align-items: center; }
  .edit-result-wrapper .tag {
    text-transform: uppercase;
    font-weight: 600;
    width: auto;
    color: white;
    padding: 10px;
    border-radius: 3px;
    text-align: center; }
  .edit-result-wrapper .success {
    background-color: #67d1b6; }
  .edit-result-wrapper .error {
    background-color: #df3f41; }
