/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.isdin-custom-modal {
  min-width: 50vw; }
  @media (max-width: 1600px) {
    .isdin-custom-modal {
      min-width: 60vw; } }
  @media (max-width: 1200px) {
    .isdin-custom-modal {
      min-width: 70vw; } }
  @media (max-width: 992px) {
    .isdin-custom-modal {
      min-width: 80vw; } }
  @media (max-width: 576px) {
    .isdin-custom-modal {
      height: 100vh;
      min-width: 100vw; } }
  .isdin-custom-modal.small {
    min-width: 30vw; }
  .isdin-custom-modal__container {
    min-height: 20vh;
    padding-left: 15px;
    padding-right: 15px; }
  .isdin-custom-modal_link {
    padding-left: 15px;
    padding-right: 15px; }
  .isdin-custom-modal__title {
    margin-bottom: 40px;
    font-size: 1.6rem; }
  .isdin-custom-modal__subtitle {
    margin: 10px 0;
    font-size: 1rem;
    display: block; }
  .isdin-custom-modal__subtitleUp {
    margin: -30px 0 10px 0;
    font-size: 1rem;
    display: block; }
  .isdin-custom-modal__image {
    margin-top: 30px;
    width: 100%; }
  .isdin-custom-modal__users-list {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden; }
    .isdin-custom-modal__users-list .ant-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px; }
      .isdin-custom-modal__users-list .ant-row::before, .isdin-custom-modal__users-list .ant-row::after {
        content: none; }
      .isdin-custom-modal__users-list .ant-row:hover {
        background: #f4f4f4; }

.no-center-button {
  display: inline-block;
  margin-right: 20px; }

.request-order-modal {
  width: 900px !important; }
  .request-order-modal__title {
    font-size: 24px;
    margin-bottom: 20px; }
