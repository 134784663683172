.duplicate.header {
  margin-bottom: 20px; }

.duplicate.title {
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 5px; }

.duplicate.icon {
  margin-right: 20px; }

.duplicate.show-more {
  margin-top: 20px; }

.duplicate.show-more button {
  border: none;
  box-shadow: none; }

.duplicate.container-buttons {
  text-align: right;
  margin-bottom: 1rem; }

.duplicate.container-button-center {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  justify-content: center; }

.duplicate.container-buttons button {
  margin: 5px;
  justify-content: center; }

.duplicate.advice {
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 5px;
  color: red; }
