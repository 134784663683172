/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.notFound {
  max-width: 450px;
  text-align: center;
  color: #d8d8d8;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .notFound__icon {
    font-size: 60px;
    margin-bottom: 16px; }
  .notFound__text {
    font-weight: 700;
    font-size: 22px; }
