/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.updatePassword {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 5%;
  justify-content: center;
  align-items: center;
  background-color: white; }
  .updatePassword__logo {
    background-image: url("../img/ISDIN_LOGO.png");
    background-size: 180px;
    background-repeat: no-repeat;
    height: 90px;
    width: 200px;
    display: block;
    margin: auto; }
    .updatePassword__logo--row {
      text-align: center; }
  .updatePassword__boxContent {
    background-color: white;
    border-radius: 6px;
    width: 400px;
    height: 350px; }
    @media (max-width: 576px) {
      .updatePassword__boxContent {
        max-width: 300px; } }
    .updatePassword__boxContent form {
      width: 100%; }
      .updatePassword__boxContent form label {
        font-size: 16px;
        font-weight: 400; }
      .updatePassword__boxContent form .label--text {
        padding-top: 50px;
        font-size: 22px;
        color: white;
        font-weight: bold; }
      .updatePassword__boxContent form button {
        height: 50px;
        width: 60%;
        margin-top: 10px; }
      .updatePassword__boxContent form input {
        border-bottom-color: black;
        border-top-color: white;
        border-left-color: white;
        border-right-color: white;
        color: black;
        margin-bottom: 0px !important; }
      .updatePassword__boxContent form .updateButtom {
        padding-left: 76px; }
  @media (max-width: 576px) {
    .updatePassword__input {
      max-width: 300px; } }
  .updatePassword__row {
    width: 100%; }

a.ant-btn {
  height: 50px;
  padding-top: 9px;
  margin-left: 10px;
  width: 60%; }
