.webeatCoverVideoPreviewButton {
  flex: 0 1 42px;
  padding: 0;
  margin-top: 0px;
  box-shadow: none; }

.webeatCoverVideoPreviewModal {
  margin: auto !important;
  width: 60vw !important; }
  .webeatCoverVideoPreviewModal__preview {
    text-align: center; }
