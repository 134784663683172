/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.ant-tabs-bar {
  margin: 0; }

.tabContainer {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  border-top: 0;
  padding: 8px 6px 0 6px;
  background-color: #ffffff; }

.noDisplay {
  display: none; }

.rowContainer {
  padding-top: 4px;
  padding-bottom: 4px; }
  .rowContainer .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .rowContainer .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    cursor: default;
    color: rgba(0, 0, 0, 0.85); }

.ant-drawer-body {
  padding: 0 14px 0 14px; }

.drawerContainer {
  width: 500px; }

.counter {
  margin-right: 10px; }

.ant-drawer-header {
  padding: 6px 12px 6px 16px; }

.ant-page-header-heading-sub-title {
  padding-top: 2px;
  font-size: 18px;
  color: black; }

.pageHeader {
  padding: 14px 6px 6px 6px; }
  .pageHeader__title {
    font-size: 18px; }
  .pageHeader__subTitle {
    font-size: 18px; }
  .pageHeader .ant-page-header-back {
    font-size: 24px; }
