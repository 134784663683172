.webeat-svg-selector__button {
  width: 100%; }

.webeat-svg-selector__modal {
  height: 70vh; }

.webeat-svg-selector__container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  overflow-x: auto;
  padding: 0 15px;
  width: 100%;
  max-height: 40vh; }

.webeat-svg-selector__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-top: 4px;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
  transition: border 0.2s all ease-in-out; }

.webeat-svg-selector__item:hover {
  border: 2px solid #df3f41; }

.webeat-svg-selector__item__svg {
  max-width: 100%; }

.webeat-svg-selector-container__modal {
  display: flex;
  justify-content: space-between;
  height: 100%; }
