/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.i18n__title {
  font-size: 1.1rem;
  font-weight: 700; }

.i18n__originalText {
  margin-top: 20px;
  font-weight: 500;
  font-size: 1rem; }

.i18n__input, .i18n__buttons {
  margin-top: 60px; }

.i18n__buttons--cancel {
  margin-left: 20px; }

.i18n__challenge--input {
  margin-top: 15px; }

.i18n__challenge--description {
  margin-top: 15px;
  margin-bottom: 30px; }

.i18n__resource--title {
  border: 1px solid #a8a8a8;
  padding: 10px;
  font-weight: 600; }

.i18n__parentResource {
  border: 1px solid #a8a8a8;
  border-top: none;
  padding: 30px; }
  .i18n__parentResource--title {
    font-weight: 600;
    text-transform: capitalize;
    display: inline-block; }
  .i18n__parentResource--input {
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block; }

.i18n__childResource {
  padding-top: 10px; }

.i18n__divider {
  background-color: transparent !important;
  min-width: 90% !important;
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 5px !important;
  margin-top: 15px !important; }
