/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.assignCenter {
  min-height: 70vh;
  padding-left: 20px;
  padding-right: 20px; }
  .assignCenter__title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 20px; }
  .assignCenter__table .ant-pagination {
    float: left; }
  .assignCenter__table .ant-table {
    border: 1px solid #d8d8d8;
    border-bottom: none; }
  .assignCenter__table .ant-table-thead > tr > th {
    background: white; }
  .assignCenter__editButton {
    cursor: pointer; }
    .assignCenter__editButton--icon {
      color: #df3f41; }
  .assignCenter__actionButtons {
    margin-top: -48px; }
    .assignCenter__actionButtons--noData {
      margin-top: 20px; }
  .assignCenter__select {
    margin-top: 40px; }
    .assignCenter__select--title {
      margin-top: 20px; }
    .assignCenter__select--input {
      margin-top: 10px; }

.saveButton {
  margin-left: 20px; }
