.md-editor:hover, .md-editor:focus {
  box-shadow: 0 0 0 1px #eb6967, 0 0 0 #eb6967, 0 1px 1px #eb6967; }

.md-editor--error {
  box-shadow: 0 0 0 1px #dc283e, 0 0 0 #dc283e, 0 1px 1px #dc283e; }

.md-preview {
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-height: 32px;
  cursor: text; }
  .md-preview:hover {
    border-color: #eb6967; }
  .md-preview--disabled {
    color: #00000040;
    background-color: #f5f5f5;
    cursor: not-allowed; }
    .md-preview--disabled:hover {
      border-color: #d9d9d9; }
  .md-preview--error {
    border: 1px solid #dc283e; }
    .md-preview--error:hover {
      border-color: #dc283e; }

.md-toolbar-helpers {
  position: absolute;
  top: 4px;
  right: 12px;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 16px; }
